<template>
    <div>
        <div v-if="!showError">Een moment geduld, uw e-mailadres wordt geactiveerd...</div>
        <div v-if="showError" >{{message}}</div>
    </div>
</template>

<script>
export default {
  name: 'EmailConfirm',
  data () {
    return {
      loading: false,
      showError: false,
      message: ''
    }
  },
  mounted () {
    this.$store.dispatch('auth/confirmEmail', {
      token: this.$route.query.token,
      email: this.$route.query.email
    }).then((res) => {
      console.log(res)
      if (res.status && res.status === 200) {
        this.$router.push({ name: 'login' })
        this.showError = false
        this.loading = false
      } else {
        this.message = res.response.data.message
        this.showError = true
        this.loading = false
      }
    }).catch((err) => {
      this.message = err.message
      this.showError = true
      this.loading = false
    })
  }
}
</script>
